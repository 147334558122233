/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useTable,
} from 'react-table';
import {Table, Row, Col, Button, Form} from 'react-bootstrap';
import {DefaultColumnFilter} from './Filter';

import {ProductsGlobalFilter} from './GlobalSearchFilter';
import {variables} from 'utils/constant';

interface GlobalFilterProps {
  readonly preGlobalFilteredRows?: any;
  readonly globalFilter?: any;
  readonly setGlobalFilter?: any;
  readonly SearchPlaceholder?: string;
  readonly isProductsFilter?: boolean;
  isSearchByID?: boolean;
  onSearchByID?: any;
  onSearch?: any;
}

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  SearchPlaceholder,
  isProductsFilter,
  isSearchByID,
  onSearchByID,
  onSearch,
}: GlobalFilterProps): React.JSX.Element {
  // const [value, setValue] = React.useState(globalFilter);
  const [searchType, setSearchType] = React.useState<string>('normal');

  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    undefined,
  );
  const [orderIDSearchValue, setOrderIDSearchValue] = React.useState<
    string | undefined
  >(undefined);
  // const onChange = (value: any): void => {
  //   setGlobalFilter(value ?? undefined);
  // };
  const handleNormalSearchChange = (value: string): void => {
    setSearchValue(value);
    // Call the handler for normal search
    onSearch?.(value);
  };

  const handleOrderIDSearchChange = (): void => {
    // If orderIDSearchValue is blank, show all data
    onSearchByID?.(orderIDSearchValue ?? '');
  };
  const handleSearchTypeChange = (selectedType: string): void => {
    setSearchType(selectedType);
    setSearchValue('');
  };

  return (
    <React.Fragment>
      <Col className="col-sm">
        <div className="d-flex justify-content-between gap-1 px-1 py-2">
          <Col md={7}></Col>
          <div className="d-flex align-items-center mb-2">
            {(isProductsFilter ?? false) && <ProductsGlobalFilter />}
            {isSearchByID && (
              <React.Fragment>
                <Col className="col-md px-3">
                  <div className="d-flex align-items-center">
                    <select
                      value={searchType}
                      onChange={e => {
                        handleSearchTypeChange(e.target.value);
                      }}
                      className="form-select me-2"
                      style={{width: '50%'}}>
                      <option value="normal">Search By Payment</option>
                      <option value="searchByID">Search By Order ID</option>
                    </select>

                    {searchType === 'normal' ? (
                      <label htmlFor="search-bar-0" className="search-label">
                        <input
                          onChange={e => {
                            setSearchValue(e.target.value);
                            handleNormalSearchChange(e.target.value);
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          placeholder={SearchPlaceholder}
                          value={searchValue ?? ''}
                        />
                      </label>
                    ) : (
                      <label htmlFor="search-bar-0" className="search-label">
                        <input
                          onChange={e => {
                            setOrderIDSearchValue(e.target.value);
                            handleOrderIDSearchChange();
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          placeholder="Search By Order ID..."
                          value={orderIDSearchValue ?? ''}
                        />
                      </label>
                    )}
                  </div>
                </Col>
              </React.Fragment>
            )}
          </div>
        </div>
      </Col>
      {!isSearchByID && (
        <Col className="col-sm">
          {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}

          <div className="d-flex justify-content-sm-end px-2 py-2">
            <label htmlFor="search-bar-0" className="search-label">
              {/* <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span> */}
              <input
                onChange={e => {
                  setSearchValue(e.target.value);
                  handleNormalSearchChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={SearchPlaceholder}
                value={searchValue ?? ''}
              />
            </label>
          </div>
          {/* </div> */}
        </Col>
      )}
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isAddOptions?: any;
  divClassName?: any;
  tableClassName?: any;
  theadClassName?: any;
  tableClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  isAddUserList?: any;
  onClick?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  className?: any;
  pageValue?: any;
  handleNextPagination?: any;
  handlePrevPagination?: any;
  customPageSizeOptions?: any;
  iscustomPageSize?: boolean;
  SearchPlaceholder: string;
  isProductsFilter?: boolean;
  setPageSize?: (pageSize: number) => void;
  pageSize?: number;
  currentData?: any;
  buttonText?: string;
  sortColumn?: any;
  sortDirection?: string;
  isSearchByID?: boolean;
  isNotPagination?: boolean;
  onSearchByID?: (value: string) => void;
  onSearch?: (value: string) => void;
  handleSortByColumn?: (columnName: string) => void;

  isChangeStatus?: boolean;
  isCategory?: boolean;
  statusClickevent?: any;
}

const TableContainer = ({
  columns,
  onSearchByID,
  onSearch,
  data,
  tableClass,
  theadClass,
  isBordered,
  isGlobalFilter,
  isProductsFilter,
  isAddOptions,
  isAddUserList,
  handleUserClick,
  handleCustomerClick,
  setPageSize,
  pageSize,
  isAddCustList,
  handleNextPagination,
  handlePrevPagination,
  customPageSize,
  pageValue,
  iscustomPageSize,
  customPageSizeOptions,
  SearchPlaceholder,
  currentData,
  buttonText,
  onClick,
  sortColumn,
  sortDirection,
  handleSortByColumn,
  isNotPagination,
  isSearchByID,
  isChangeStatus,
  isCategory,
  statusClickevent,
}: TableContainerProps): React.JSX.Element => {
  const [tableData, setTableData] = useState(data ?? []);

  useEffect(() => {
    setTableData(data);
  }, [data]);
  const isDataAvailable = data?.length;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex},
    setPageSize: setPageSizeTable, // Use setPageSize to update the page size
  } = useTable(
    {
      columns,
      data: useMemo(() => tableData, [tableData]),
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortColumn: [{id: sortColumn, desc: sortDirection === 'desc'}],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    setTableData(data);
    setPageSizeTable?.(customPageSize);
  }, [data, customPageSize, setPageSizeTable]);
  const generateSortingIndicator = (
    columnId: string,
    sortColumn: any,
    sortDirection: any,
  ): React.ReactElement => {
    if (sortColumn && sortColumn === columnId) {
      return sortDirection === 'asc' ? (
        <span className="cursor-pointer">&#9650;</span>
      ) : (
        <span className="cursor-pointer">&#9660;</span>
      );
    }
    return <span className="cursor-pointer">&#9650;</span>;
  };
  const onChangeInSelect = (event: any): void => {
    const newSize = Number(event.target.value);
    setPageSize?.(newSize);
    // Add this line to update the pageSize state
  };

  return (
    <Fragment>
      <Row className="align-items-center">
        {iscustomPageSize != null && (
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          <Col md={customPageSizeOptions || 2} className="px-3">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter != null && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            SearchPlaceholder={SearchPlaceholder}
            isProductsFilter={isProductsFilter}
            isSearchByID={isSearchByID}
            onSearch={onSearch}
            onSearchByID={onSearchByID}
          />
        )}
        {isChangeStatus != null && isChangeStatus && (
          <Col sm="auto">
            <div>
              <Form.Select
                className="form-select"
                id="product_status"
                name="product_status"
                onChange={async e => {
                  await statusClickevent(e);

                  // setSelectedProductStatus(e.target.value);
                }}>
                {isCategory ? (
                  <>
                    <option value="">Select Category Status</option>
                    <option value="1">Active</option>
                    <option value="2">InActive</option>
                  </>
                ) : (
                  <>
                    <option value="">Select Product Status</option>
                    <option value={variables.PRODUCT_ACTIVE_STATUS_ID}>
                      Active
                    </option>
                    <option value={variables.PRODUCT_INACTIVE_STATUS_ID}>
                      InActive
                    </option>
                  </>
                )}
              </Form.Select>
            </div>
          </Col>
        )}
        {isAddOptions != null && (
          <Col sm="auto">
            <div>
              <Button
                role="add-Btn"
                variant="success"
                onClick={onClick}
                className="add-btn">
                <i className="bi bi-plus-circle me-1 align-middle"></i>
                {buttonText}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      {isAddUserList != null && (
        <Col sm="7">
          <div className="text-sm-end">
            <Button
              type="button"
              variant="primary"
              className="btn mb-2 me-2"
              onClick={handleUserClick}>
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create New User
            </Button>
          </div>
        </Col>
      )}
      {isAddCustList != null && (
        <Col sm="7">
          <div className="text-sm-end">
            <Button
              type="button"
              variant="success"
              className="btn-rounded mb-2 me-2"
              onClick={handleCustomerClick}>
              <i className="mdi mdi-plus me-1" />
              Customers
            </Button>
          </div>
        </Col>
      )}

      <div
        className="table-responsive react-table"
        style={isDataAvailable > 0 ? {minHeight: '280px'} : {}}>
        <Table
          hover
          {...getTableProps()}
          className={tableClass}
          bordered={isBordered}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    <th
                      key={column.id}
                      onClick={() => {
                        if (
                          column.id !== 'parentBox' &&
                          column.id !== 'Action' &&
                          handleSortByColumn
                        ) {
                          handleSortByColumn(column.id);
                        }
                      }}
                      {...column.getHeaderProps()}>
                      <div className="d-flex align-items-center">
                        {column.render('Header')}
                        {column.id !== 'parentBox' &&
                          column.id !== 'Action' &&
                          generateSortingIndicator(
                            sortColumn,
                            column.id,
                            sortDirection,
                          )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          className="scrollable-cell"
                          key={cell.id}
                          {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      {page.length <= 0 && (
        <div className="text-center fw-bold fs-2">No data found</div>
      )}
      {!isNotPagination && (
        <Row className="align-items-center mt-2 py-2 px-2 gy-2 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing{' '}
              <span className="fw-semibold">{pageIndex + pageValue}</span> of{' '}
              <span className="fw-semibold">{currentData || 1}</span> Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated mb-0 justify-content-center justify-content-sm-start">
              <li
                className={
                  canPreviousPage === null ? 'page-item disabled' : 'page-item'
                }>
                <Button
                  onClick={() => handlePrevPagination?.(pageValue)}
                  variant="link"
                  className="page-link">
                  Previous
                </Button>
              </li>

              {/* <div>
               {pageOptions.map((item: any, key: number) => (
             
                <li className="page-item">
                 
                  <Button
                    variant="link"
                    className={
                      pageIndex === item ? 'page-link active' : 'page-link'
                    }>
                    {pageValue}
                  </Button>
                </li>
            
            ))}
            </div> 
            */}
              <li className="page-item">
                <Button variant="link" className={'page-link active'}>
                  {pageValue}
                </Button>
              </li>
              <li
                className={
                  canNextPage === null ? 'page-item disabled' : 'page-item'
                }>
                <Button
                  onClick={() => {
                    handleNextPagination?.(pageValue);
                  }}
                  variant="link"
                  className="page-link">
                  Next
                </Button>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
