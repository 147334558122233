import React, {useMemo, useState, useEffect} from 'react';

import {Dropdown} from 'react-bootstrap';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import OrderTableContainer from './OrderTableContainer';
import {ORDER_STATUS} from 'Common/constants/layout';
import OrderStatusModal from './StatusModal';
import DeliveryPersonAssignModal from './DeliveryPersonAssignModal';
interface OrderListType {
  id: number;
  customer_address: {
    customer_first_name: string;
    customer_last_name: string;
    city_name: string;
    phone_number: string;
  };
  customer_id: string;
  final_total_amount: number;
  order_status: string;
  created_at: string;
  payment_type: string;
}
const OrderListTable = (): JSX.Element => {
  const [orderList, setOrderList] = React.useState<OrderListType[] | null>(
    null,
  );
  const [orderDetails, setOrderDetails] = useState<any>({});

  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const defaultPage = 1;

  const [pageValue, setPageValue] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('id');

  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRecords, setTotalRecords] = useState<number>(defaultPage);
  const currentData = Math.ceil(totalRecords / pageSize);
  const [orderStatusFilter, setOrderStatusFilter] = useState<number | ''>('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<number | ''>('');
  const [deliveryPersonId, setDeliveryPersonId] = useState<number | ''>('');

  useEffect(() => {
    void getUsersList();
  }, [
    pageValue,
    pageSize,
    orderStatusFilter,
    paymentTypeFilter,
    isStatusUpdated,
    deliveryPersonId,
  ]);

  async function getUsersList(): Promise<void> {
    try {
      const res = await ApiUtils.getOrderList(
        `?sort_direction=desc&page=${pageValue}&page_size=${pageSize}&sort_column=${sortColumn}&sort_direction=${sortDirection}&order_status=${orderStatusFilter}&payment_type=${paymentTypeFilter}&delivery_person_id=${deliveryPersonId}`,
      );
      const mappedData = (res as any)?.data?.data?.map(
        (order: OrderListType) => {
          return {
            id: order?.id,
            customer_id: order?.customer_id,
            city_name: order?.customer_address?.city_name,
            final_total_amount: order?.final_total_amount,
            order_status: order?.order_status,
            phone_number: order?.customer_address?.phone_number,
            order_date: order?.created_at.split(' ')[0],
            payment_type: order?.payment_type,
            delivery_person: order,
          };
        },
      );

      setOrderList(mappedData);
      setTotalRecords((res as any)?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  }
  const handleOrderStatusFilter = (orderId: any): void => {
    setOrderStatusFilter(orderId);
  };
  const handlePaymentTypeFilter = (paymentId: any): void => {
    setPaymentTypeFilter(paymentId);
  };
  const handleDeleverySelect = (data: any): void => {
    setDeliveryPersonId(data);
  };

  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };

  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };
  const handleDeleteOrder = (): void => {
    void Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.isConfirmed) {
        void Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success',
        });
      }
    });
  };

  const handleSortByColumn = async (column: string): Promise<void> => {
    try {
      let newSortDirection = 'asc';

      if (column === sortColumn) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'desc';
      }

      const response: any = await ApiUtils.getOrderList(
        `?sort_direction=desc&page=${pageValue}&page_size=${pageSize}&sort_column=${column}&sort_direction=${newSortDirection}&order_status=${orderStatusFilter}&payment_type=${paymentTypeFilter}`,
      );

      const mappedData = response?.data?.data?.map((order: OrderListType) => {
        return {
          id: order?.id,
          customer_id: order?.customer_id,
          city_name: order?.customer_address?.city_name,
          final_total_amount: order?.final_total_amount,
          order_status: order?.order_status,
          phone_number: order?.customer_address?.phone_number,
          order_date: order?.created_at.split(' ')[0],
          payment_type: order?.payment_type,
        };
      });

      setSortDirection(newSortDirection);
      setOrderList(mappedData);
      setSortColumn(column);

      setTotalRecords(response?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [orderIdforDelivery, setOrderIdForDelivery] = useState();

  const handleAssignDelivery = (data: any): any => {
    setDeliveryModal(!deliveryModal);
    setOrderIdForDelivery(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },
      {
        Header: 'Customer ID',
        accessor: 'customer_id',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },

      {
        Header: 'Date',
        accessor: 'order_date',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },
      {
        Header: 'City Name',
        accessor: 'city_name',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },
      {
        Header: 'Total Amount',
        accessor: 'final_total_amount',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },
      {
        Header: 'Payment Method',
        accessor: 'payment_type',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          switch (cellProps?.value) {
            case 21:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  {' '}
                  Cash
                </span>
              );
            case 22:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  Online Payment
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  NA
                </span>
              );
          }
        },
      },
      {
        Header: 'Order Status',
        accessor: 'order_status',

        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          switch (cellProps?.value) {
            case ORDER_STATUS.PENDING:
              return (
                <span className="badge bg-warning-subtle text-warning text-uppercase">
                  {' '}
                  Pending
                </span>
              );
            case ORDER_STATUS.ACCEPTED:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  Accepted
                </span>
              );
            case ORDER_STATUS.DELIVERY_PERSON_ASSIGN:
              return (
                <span className="badge bg-info-subtle text-info text-uppercase">
                  Delivery Person Assigned
                </span>
              );
            case ORDER_STATUS.DELIVERED:
              return (
                <span className="badge bg-secondary-subtle text-secondary text-uppercase">
                  Delivered
                </span>
              );
            case ORDER_STATUS.ON_THE_WAY:
              return (
                <span className="badge bg-secondary-subtle text-secondary text-uppercase">
                  Delivered
                </span>
              );
            case ORDER_STATUS.CANCELED:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  Canceled
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  NA
                </span>
              );
          }
        },
      },
      {
        Header: 'Delivery Person',
        accessor: 'delivery_person',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <div className="ml-auto">
              {rowData.order_status === ORDER_STATUS.ACCEPTED ? (
                <button
                  onClick={() => {
                    handleAssignDelivery(rowData.id);
                  }}
                  className="btn btn-primary btn-sm">
                  Assign Delivery Person
                </button>
              ) : rowData.order_status ===
                  ORDER_STATUS.DELIVERY_PERSON_ASSIGN ||
                rowData.order_status === ORDER_STATUS.DELIVERED ||
                rowData.order_status === ORDER_STATUS.ON_THE_WAY ? (
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={rowData?.delivery_person?.delivery_person_image}
                    alt="Delivery Person"
                    className="rounded-circle mr-2"
                    style={{width: '40px', height: '40px'}}
                  />
                  <span>
                    {rowData?.delivery_person?.delivery_person_first_name}{' '}
                    {rowData?.delivery_person?.delivery_person_last_name}
                  </span>
                </div>
              ) : (
                <div className="text-center"> - </div>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (
            <Dropdown className="text-center">
              <Dropdown.Toggle className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item href={`/order-detail/${cellProps.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View Order
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item href={`/order-invoice-detail/${cellProps.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View Invoice
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={async () => {
                      setOrderDetails(cellProps);
                      toogleStatus();
                      setIsStatusUpdated(false);
                    }}
                    href="#"
                    className="remove-list">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                    Update Order
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={handleDeleteOrder}
                    href="#"
                    className="remove-list">
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [],
  );
  const [statusModal, setStatusModal] = useState(false);

  function toogleStatus(): void {
    setStatusModal(!statusModal);
  }

  return (
    <>
      <OrderTableContainer
        columns={columns}
        data={orderList ?? []}
        isGlobalFilter={true}
        handleNextPagination={handleNextPagination}
        handlePrevPagination={handlePrevPagination}
        pageValue={pageValue}
        iscustomPageSize={false}
        isBordered={true}
        customPageSize={pageSize}
        pageSize={pageSize}
        currentData={currentData}
        setPageSize={setPageSize}
        className="custom-header-css"
        tableClass="table-centered align-middle table-nowrap mb-0"
        theadClass="text-muted table-light"
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        handleSortByColumn={handleSortByColumn}
        handleOrderStatusFilter={handleOrderStatusFilter}
        handlePaymentTypeFilter={handlePaymentTypeFilter}
        setOrderStatusFilter={setOrderStatusFilter}
        handleDeleverySelect={handleDeleverySelect}
      />
      <OrderStatusModal
        toogleStatus={toogleStatus}
        details={orderDetails}
        showModal={statusModal}
        setIsStatusUpdated={setIsStatusUpdated}
      />
      <DeliveryPersonAssignModal
        toogleStatus={handleAssignDelivery}
        details={orderIdforDelivery}
        showModal={deliveryModal}
        setIsStatusUpdated={setIsStatusUpdated}
      />
    </>
  );
};

export default OrderListTable;
