const tooltipMessage = {
  FormSKU:
    'Please enter the product SKU, which should only contain numbers. Ensure there are no letters or special characters for a valid SKU.',
  FormProductTitle:
    'Please enter the name of the product without using any special characters. Keep it simple and clear to ensure a better experience for your users',
  FormProductDescription:
    "Please enter the description of the product. Keep it simple and clear to ensure a better experience for your users.You can also use the 'Select Template' button to create a new template for your product description.",
  FormCategoryList: 'Please choose a category from the category list.',
  FormBrandList: 'Please choose a brand from the brand list.',
  FormGstList: 'Please choose a GST ID from the GST list.',
  ProductStatus:
    "Easily toggle the product status! Use the switch to mark it as Available for purchase or Unavailable when it's out of stock.",
  SelectOption: 'Please select product option.',
  SelectOptionValue: 'Please select product option value.',
  ProductQuantity:
    'Please enter the quantity of the product for this option value.',
  ProductPrice: 'Please enter the price of the product for this option value.',
  ProductSpecialPrice:
    'Please enter the special price of the product for this option value.Ensure that the special price is always lower than the product price.',
  ProductWeight: 'Please enter the product weight for this option value.',
  ProductLength: 'Please enter the product length for this option value.',
  ProductWidth: 'Please enter the product width for this option value.',
  ProductHeight: 'Please enter the product height for this option value.',
  ProductMinimumQunatity:
    "Please enter the product's minimum quantity required for this option value.",
  ProductCostCompany:
    "Please add the company's product cost for this option. Ensure that the company's cost is always lower than the product price.",
  WeigthClass:
    'Please select the weight Class for this option value. Weight class Like KG, Gram',
  lengthClass:
    'Please select the length Class for this option value. Length class like cm, mm, inch',

  CategoryName:
    'Please enter the category name. It can include letters, numbers, and special characters.',
  SelectPerentCatgory:
    'If you are creating or updating child category then please select the parent category from Parent Category list.',
  SelectBrand:
    'Please select the brand from brand list. You can select multiple brands from brand list',
  CategoryStatus:
    'Please select the status whether this category is active or inactive',

  BrandName: 'Please enter the brand name of the products.',

  AddOption: 'Please enter the option name.',
  OptionType:
    'Please select the option type.Use checkbox for multiple selections and radio or a select for single selection',
  OptionStatus:
    'Please select the status whether the option is active or inactive',
  OptionValueImage: 'Please add image of option value',
  OptionValueName: 'Please add name of option value',
  OptionValueLabel: 'Please add label of option value',
  OptionValueStaus:
    'Please select status of option value, whether the option value is active or inactive.',

  GstTitle: 'Please add GST Tax title',
  GstCode: 'Please add your GST Code',
  GstPercentage: 'Please add GST Percentage',

  Orderstatus: 'Update the  order status.',

  CouponType: 'Please select coupon type %Discount or Fixed Discount',
  CouponName: 'Please enter name of the coupon.',
  CouponCode: 'Please enter code for the coupon.',
  CouponValue: 'Please enter value for the coupon.',
  CouponMedia: 'Please select the type of media like facebook, instagram, etc.',
  CouponCategory: 'Please select the category of the product.',
  CouponProduct:
    'Please select the product on the basis of the selected category.',
  CouponOptionType:
    'Please select the product option on the basis of the selected product.',
  CouponOptionValue:
    'Please select the product option value on the basis of the selected product option.',
  CouponMaximumAmount: 'Please enter the maximum amount of the product.',
  CouponUseTime: 'Please enter the number of times this coupon can be used.',
  CouponMinimumOrderAmount:
    'Please specify the minimum amount required to use this coupon.',
  CouponMaximumUse:
    'Please provide the maximum allowable usage details for the coupon.',
  CouponStartDate: 'Set the start date from which the coupon will be active.',
  CouponEndDate:
    'Set the expiration date for when the coupon will become inactive.',

  CouponStaus:
    'Please select status of the coupon, whether the coupon is active or inactive.',
  CouponTerms: 'Please add the Terms and condition of the coupon.',

  DiscountName: 'Please enter the discount name.',
  DiscountStaus:
    'Please select status of the discount, whether the dicount is active or inactive.',
  DiscountType: 'Please select the type of discount.',
  DiscountPriceType: 'Please enter how much price is up & down',
  DiscountCategory: 'Please select category for discount.',
  DiscountProduct: 'Please select product for discount.',
  DiscountProductOption: 'Please select product option for discount.',
  DiscountProductOptionValue:
    'Please select product option value for discount.',
  DiscountStartDate:
    'Set the start date from which the discount will be applicable.',
  DiscountEndDate:
    'Set the expiration date after which the discount will no longer be active.',

  DeliveryImage: 'Please add image.',
  DeliveryFirstName: 'Please enter first name.',
  DeliveryLastName: 'please enter last name.',
  DeliveryEmail: 'please enter email id.',
  DeliveryMobile: 'please enter mobile number.',
  DeliveryPassword: 'please enter password.',

  FormProductTag: 'Please choose a Tag for product.',
};

export {tooltipMessage};
