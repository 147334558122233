/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {useEffect, useMemo, useState} from 'react';
import TableContainer from 'Common/TableContainer';
import {toast} from 'react-toastify';
import {Card, Col, Container, Dropdown, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import ApiUtils from 'api/ApiUtils';
import {variables} from 'utils/constant';
import CheckboxInput from './CheckboxInput';
import {useDispatch, useSelector} from 'react-redux';
import {setProductIds} from 'slices/statusChange/reducer';
import {changeStatus} from 'slices/thunk';

// representing a table of products listing.

interface ProductItemType {
  data: {
    data: [];
    page_size: number;
    total: number;
  };
  message: string;
}
const ProductTable = (): JSX.Element => {
  const [productItem, setProductItem] = React.useState<
    ProductItemType | undefined
  >();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const defaultPage = 1;
  const [pageValue, setPageValue] = useState(defaultPage);
  const [sortColumn, setSortColumn] = useState('id');

  const [search, setSearch] = useState<string>('');

  const [sortDirection, setSortDirection] = useState('asc');

  const [totalRecords, setTotalRecords] = useState(defaultPage);

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  // const {currentLocation}: any = useSelector(state => state);
  // console.log('🚀 ~ index ~ data:', currentLocation.currentLocation);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const category_Id = searchParams.get('category_id');
  const [isCheckboxChanged, setIsCheckboxChanged] = useState(false);
  const currentData = Math.ceil(totalRecords / pageSize);
  const dispatch = useDispatch<any>();
  const [isStatusChange, setisStatusChange] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [Checked, seChecked] = useState(false);

  useEffect(() => {
    ApiUtils.getProductList(
      `?page=${pageValue}&page_size=${pageSize}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search=${search}&category_id=${
        category_Id ?? ''
      }`,
    )
      .then((data: any): any => {
        setProductItem(data as ProductItemType);
        setTotalRecords((data as ProductItemType)?.data?.total);
      })
      .catch((error: any) => {
        toast.error(error.message);
      });
    setisStatusChange(false);
    setAllChecked(false);
  }, [pageValue, pageSize, search, category_Id, location, isStatusChange]);

  const handleDeleteProduct = async (id: any): Promise<void> => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (result.isConfirmed) {
      ApiUtils.deleteProduct(id)
        // eslint-disable-next-line unused-imports/no-unused-vars
        .then(async data => {
          await Swal.fire({
            title: 'Deleted!',
            text: (data as any).message,
            icon: 'success',
          });
          ApiUtils.getProductList(`?page=${pageValue}&page_size=${pageSize}`)
            .then(async (data: any) => {
              setProductItem(data);
            })
            .catch((error: any) => {
              toast.error(error.message);
            });
        })
        .catch((error: any) => {
          toast.error(error.message);
        });
    }
  };

  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };
  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };

  // const truncateText = (text: string, maxLength: number): any => {
  //   return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  // };
  const handleSortByColumn = (column: string): void => {
    let newSortDirection = 'asc';

    if (column === sortColumn) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      newSortDirection = 'desc';
    }

    ApiUtils.getProductList(
      `?page=${pageValue}&page_size=${pageSize}&sort_column=${column}&sort_direction=${newSortDirection}&search=${search}&category_id=${category_Id}`,
    )
      .then((data: any): any => {
        setProductItem(data as ProductItemType);
        setSortDirection(newSortDirection);
        setSortColumn(column);

        setTotalRecords((data as ProductItemType)?.data?.total);
      })
      .catch((error: any) => {
        toast.error(error.message);
      });
  };

  // Add a new function to handle normal search
  const handleSearch = (value: string): void => {
    setSearch(value);
  };

  // const handleUpdateStatus = async (): Promise<void> => {
  //   if (selectedProductIds.length === 0) {
  //     toast.error('Please select at least one product.');
  //     return;
  //   }

  //   // Check if any of the selected products are currently inactive
  //   const anyInactive = selectedProductIds.some(
  //     id =>
  //       productItem?.data?.data.some(
  //         (product: any) =>
  //           product.id === id &&
  //           product.status_id === variables.PRODUCT_ACTIVE_STATUS_ID,
  //       ),
  //   );

  //   // Determine the status to be sent in the payload
  //   const status = anyInactive
  //     ? variables.PRODUCT_INACTIVE_STATUS_ID
  //     : variables.PRODUCT_ACTIVE_STATUS_ID;

  //   const body = {
  //     product_id: selectedProductIds,
  //     status,
  //   };

  //   try {
  //     const response: any = await ApiUtils.updateProductStatus(body);
  //     if (response) {
  //       toast.success(response.message);
  //       // Reset selected product IDs and checkbox change state
  //       setSelectedProductIds([]);
  //       setIsCheckboxChanged(false);
  //       // Fetch updated product list
  //       ApiUtils.getProductList(
  //         `?page=${pageValue}&page_size=${pageSize}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search=${search}&category_id=${
  //           category_Id ?? ''
  //         }`,
  //       )
  //         .then((data: any): any => {
  //           setProductItem(data as ProductItemType);
  //           setTotalRecords((data as ProductItemType)?.data?.total);
  //         })
  //         .catch((error: any) => {
  //           toast.error(error.message);
  //         });
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     toast.error('Failed to update product status.');
  //     console.error('Error:', error);
  //   }
  // };

  const handleSelectAll = (): void => {
    if (allChecked) {
      setAllChecked(false);
    } else {
      setAllChecked(true);
    }
  };
  useEffect(() => {
    if (allChecked) {
      const allIds: any = productItem?.data?.data?.map(
        (item: {id: any}) => item.id,
      ); // Select all by their IDs
      setSelectedProductIds(allIds);
    } else {
      if (!Checked) {
        setSelectedProductIds([]);
      }
    }
  }, [allChecked, productItem]);

  const handleCheckboxChange = (productId: number): void => {
    let updatedSelectedProductIds: number[] = [];

    if (selectedProductIds.includes(productId)) {
      // Uncheck child, remove it from the array
      updatedSelectedProductIds = selectedProductIds.filter(
        id => id !== productId,
      );
    } else {
      // Check child, add it to the array
      updatedSelectedProductIds = [...selectedProductIds, productId];
    }

    setSelectedProductIds(updatedSelectedProductIds);
  };

  useEffect(() => {
    dispatch(setProductIds(selectedProductIds));
    if (selectedProductIds.length > 0) {
      setIsCheckboxChanged(true);
    } else {
      setIsCheckboxChanged(false);
    }
    const productlength: any = productItem?.data?.data?.length;

    if (selectedProductIds.length === productlength) {
      setAllChecked(true);
      seChecked(false);
    }
    if (selectedProductIds.length < productlength) {
      setAllChecked(false);
      seChecked(true);
    }
  }, [selectedProductIds]);

  const productIds = useSelector(
    (state: any) => state.statusChange.setProductids,
  );
  const setproductstatus = async (e: any): Promise<void> => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Want to change status for selected Products',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (result.isConfirmed) {
      dispatch(changeStatus(e.target.value, productIds, 'product'));
      setisStatusChange(true);
    }
  };
  useEffect(() => {
    setAllChecked(false);
    setSelectedProductIds([]);
  }, [isStatusChange]);
  const columns = useMemo(
    () => [
      {
        id: 'parentBox', // Give the column a unique ID
        Header: () => (
          <input
            className="parentCheckbox"
            type="checkbox"
            checked={allChecked} // This binds the checked state to `allChecked`
            value="0"
            onChange={() => {
              handleSelectAll();
            }}
          />
        ),
        disableFilters: true,
        filterable: true,
        accessor: '',
        Cell: (cell: any) => {
          return (
            <div className="d-inline-flex align-items-center gap-3">
              <input
                className={
                  selectedProductIds.includes(cell.row.original.id)
                    ? 'checked-class'
                    : 'unchecked-class'
                }
                value={cell.row.original.id}
                type="checkbox"
                checked={selectedProductIds.includes(cell.row.original.id)}
                onChange={() => {
                  handleCheckboxChange(cell.row.original.id);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Id',
        disableFilters: true,
        filterable: true,
        accessor: 'id',
        Cell: (cell: any) => {
          const isActive =
            cell.row.original.status_id === variables.PRODUCT_ACTIVE_STATUS_ID;
          return (
            <div className="d-inline-flex align-items-center gap-3">
              {category_Id !== null && (
                <CheckboxInput
                  isActive={isActive}
                  onChange={() => {
                    handleCheckboxChange(cell.row.original.id);
                  }}
                />
              )}

              {(cell.value as boolean) ? cell.value : '-'}
            </div>
          );
        },
      },
      {
        Header: 'Name',
        disableFilters: true,
        filterable: true,
        accessor: 'name',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Category',
        disableFilters: true,
        filterable: true,
        accessor: 'category_name',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        Filter: false,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Description',
        disableFilters: true,
        filterable: true,
        accessor: 'description',
        Cell: ({cell: {value}}: {cell: {value: string}}) => (
          <div dangerouslySetInnerHTML={{__html: value}} />
        ),
      },

      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <Dropdown className="text-start">
              <Dropdown.Toggle className="btn btn-ghost-primary btn-icon btn-sm arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item
                    href={`/products-edit?productId=${cellProps.row.original.id}`}>
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />{' '}
                    Edit
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={async () => {
                      await handleDeleteProduct(cellProps.row.original.id);
                    }}
                    className="remove-list">
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [category_Id, allChecked, selectedProductIds, dispatch],
  );
  return (
    <div className="page-content">
      <Container fluid={true}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-5">
            <h4 className="mb-sm-0">Product Listing</h4>
            {/* {isCheckboxChanged && (
              <Button onClick={handleUpdateStatus} variant="success">
                Update Status
              </Button>
            )} */}
          </div>
        </div>
        <div id="couponsList">
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <TableContainer
                    columns={columns}
                    data={productItem?.data?.data ?? []}
                    iscustomPageSize={true}
                    handleNextPagination={handleNextPagination}
                    handlePrevPagination={handlePrevPagination}
                    pageValue={pageValue}
                    isBordered={true}
                    customPageSize={pageSize}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    currentData={currentData}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    className="custom-header-css"
                    tableClass="table-centered align-middle table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    SearchPlaceholder="Search Products..."
                    buttonText="Add Products"
                    onSearch={handleSearch}
                    onClick={() => {
                      navigate('/products-create');
                    }}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handleSortByColumn={handleSortByColumn}
                    isChangeStatus={isCheckboxChanged}
                    statusClickevent={setproductstatus}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ProductTable;
